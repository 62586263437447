import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    WritableSignal,
    effect,
    signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { OrgSysAreaService } from 'app/modules/org-sys-area/org-sys-area.service';
import { AreaService } from 'app/modules/org-sys-area/services/area.service';
import { OrgService } from 'app/modules/org-sys-area/services/org.service';
import { SysService } from 'app/modules/org-sys-area/services/sys.service';
import { Subject, takeUntil } from 'rxjs';
import { SelectAreaDialogComponent } from '../select-md-object-dialog/select-md-object-dialog.component';

@Component({
    selector: 'select-area-form-template',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatButtonModule,
    ],
    templateUrl: './select-md-object-form-template.component.html',
    styleUrl: './select-md-object-form-template.component.scss',
})
export class SelectMdObjectFormTemplateComponent
    extends FieldType
    implements OnInit, AfterViewInit, OnDestroy
{
    private _destroy: Subject<void> = new Subject();

    private dialog: MatDialogRef<any, any>;

    protected _translocoContent = signal(null);

    private executeActionSignal: WritableSignal<string> = signal(null);

    private lastActionExecutedAt: Date | undefined = undefined;

    private type: string;

    data: WritableSignal<any> = signal(null);
    labelProp: string;

    private treeService: OrgSysAreaService<any, any>;
    constructor(
        private inject: Injector,
        private dialogService: DialogBuilderService,
        private _translocoService: TranslocoService
    ) {
        super();

        this._translocoService.selectTranslation('bp').subscribe(content => {
            this._translocoContent.set(content);
        });

        effect(
            () => {
                const code = this.executeActionSignal();
                const now = new Date();

                if (
                    !this.lastActionExecutedAt ||
                    now.getTime() - this.lastActionExecutedAt.getTime() > 1000
                ) {
                    if (code && code.startsWith('selectArea')) {
                        const selectedArea = this.treeService.selectedItem();
                        selectedArea.id = selectedArea.id.includes('_')
                            ? selectedArea.id.split('_')[1]
                            : selectedArea.id;

                        this.lastActionExecutedAt = now;
                        this.executeActionSignal.set(null);

                        if (selectedArea) {
                            setTimeout(() => {
                                this.formControl.setValue(selectedArea[this.field.props.valueProp]);

                                this.getData();
                                this.dialog.close();
                            });
                        }
                    }
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }
    ngOnInit(): void {
        this.type = this.field.props.attributes['selectionType'] as string;

        if (this.type === 'area') {
            this.treeService = this.inject.get(AreaService);
        } else if (this.type === 'system') {
            this.treeService = this.inject.get(SysService);
        } else if (this.type === 'organisation') {
            this.treeService = this.inject.get(OrgService);
        } else {
            console.error(
                `Provided selectionType (${this.type}) is not known. Please use one of the followings: 'area', 'system' or 'organisation'`
            );
            return;
        }

        this.labelProp = this.field.props.labelProp;
        this.formControl.valueChanges.pipe(takeUntil(this._destroy)).subscribe(change => {
            const now = new Date();
            if (
                !this.lastActionExecutedAt ||
                now.getTime() - this.lastActionExecutedAt.getTime() > 1000
            ) {
                this.getData();
            }
        });

        effect(
            () => {
                const state = this.treeService.state();
                if (!this.data() && state && state.length) {
                    this.getData();
                }
            },
            {
                allowSignalWrites: true,
                injector: this.inject,
            }
        );
    }

    ngAfterViewInit(): void {
        if (this.field.props.attributes['automaticOpen'] === 'true') {
            if (!this.model[this.field.key as string]) {
                this.changeArea();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.treeService && this.treeService.allowedSelectionList?.length) {
            this.treeService.allowedSelectionList = [];
        }
        this._destroy.next();
        this._destroy.complete();
    }

    findDeep(idToFind: string, objects: any[]): any | null {
        if (!objects || !objects.length) {
            return null;
        }
        for (let index = 0; index < objects.length; index++) {
            const element = objects[index];
            if (element.id === idToFind) {
                return element;
            }

            if (element.children && element.children.length) {
                const result = this.findDeep(idToFind, element.children);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    getData(): void {
        const id = this.field.formControl.value;
        this.data.set(this.findDeep(id, this.treeService.state()));
    }

    changeArea(): void {
        const currentSelection = this.formControl.getRawValue();
        let selectionType = this.field.props.attributes['allowedSelectionList'];
        if (selectionType) {
            selectionType = JSON.parse(selectionType as string);
            this.treeService.allowedSelectionList = selectionType as any;
        }
        this.treeService.isSelectionDialogOpened.set(true);

        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoContent()['selectAreaDialogTitle'],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: SelectAreaDialogComponent,
                        componentData: {
                            model: {},
                            id: currentSelection,
                            service: this.treeService,
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'selectArea',
                            style: ButtonType.raised,
                            color: 'primary',
                            title: this._translocoContent()['selectArea'],
                            manualClose: true,
                        },
                    ],
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'l',
            },
        });

        this.dialog
            .afterClosed()
            .pipe(takeUntil(this._destroy))
            .subscribe(result => {
                this.treeService.isSelectionDialogOpened.set(false);
            });
    }
}
