import { Component, effect } from '@angular/core';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { OrgSysAreaTreeComponent } from 'app/modules/org-sys-area/org-sys-area-tree/org-sys-area-tree.component';
import { OrgSysAreaService } from 'app/modules/org-sys-area/org-sys-area.service';

@Component({
    selector: 'select-area-dialog',
    standalone: true,
    imports: [OrgSysAreaTreeComponent],
    templateUrl: './select-md-object-dialog.component.html',
    styleUrl: './select-md-object-dialog.component.scss',
})
export class SelectAreaDialogComponent extends DialogBuilderComponent<any> {
    public service: OrgSysAreaService<any, any>;
    constructor() {
        super();

        effect(
            () => {
                const items = this.service.state();
                let id = this.componentData.id;
                const hasDevelopment = items.some(item => item.status === 'DEVELOPMENT');
                if (items?.length && id && !hasDevelopment) {
                    const active = items.find(item => item.status === 'ACTIVE');
                    if (active) {
                        id = `${active.versionId}_${id}`;
                    }
                    this.service.selectItemById(id);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.service = this.componentData.service;
    }
}
